import facepaint from "facepaint"

const rgbValues = {
  white: "255,255,255",
  black: "0,0,0",
  accent: "255,203,5",
  hover: "41,241,195",
}

export const grids = {
  siteRows: "'header' 60px 'main' 1fr 'footer' auto",
  siteColumns: [
    "none / 0px repeat(4, 1fr) 0px",
    "none / 40px 1fr repeat(5, 100px) 1fr 40px",
    "none / 40px 1fr repeat(7, 100px) 1fr 40px",
  ],
  siteColumnGap: 20,
}

export const colors = {
  primary: "rgb(" + rgbValues.black + ")",
  primaryBg: "rgb(" + rgbValues.white + ")",
  accent: "rgb(" + rgbValues.accent + ")",
  hover: "rgb(" + rgbValues.hover + ")",
  shadow: "7px 7px 0px rgba(" + rgbValues.hover + ", 1.0)",
}

export const fontsizes = {
  gigantic: [80, 95, 115],
  huge: [48, 58, 68],
  large: [30, 48],
  medium: [24, 28],
  small: [16, 18],
  tiny: [10, 12],
}

export const lineheights = {
  normal: 1.4,
  tight: 1,
}

export const fontfamilies = {
  sans: "'Libre Franklin', sans-serif",
  // serif: "'Libre Baskerville', serif",
}

export const fontweights = {
  light: 100,
  regular: 400,
  bold: 800,
}

export const boxsizes = {
  header: 60,
  footer: 60,
}

export const mq = facepaint([
  "@media(min-width: 740px)",
  "@media(min-width: 980px)",
  // "@media(min-width: 1120px)",
])
