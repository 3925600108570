import React from "react"

import {
  grids,
  colors,
  boxsizes,
  mq,
  fontsizes,
  fontweights,
} from "../styles/variables"

import { keyframes } from "@emotion/core"

const blink = keyframes`
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`

const HeaderStyles = mq({
  position: "fixed",
  zIndex: 200,
  display: "grid",
  grid: grids.siteColumns,
  backgroundColor: colors.primaryBg,
  gridColumnGap: grids.siteColumnGap,
  gridArea: "header",
  width: "100%",
  height: boxsizes.header,

  "&:after": {
    position: "absolute",
    content: "''",
    backgroundColor: colors.primary,
    height: 2,
    left: [20, 60],
    right: [20, 60],
    bottom: 0,
  },
})

const LogoStyles = mq({
  gridColumn: ["2 / 5", "2 / 7", "2 / 8"],
  alignSelf: "center",
  position: "relative",
  fontSize: fontsizes.small,
  fontWeight: fontweights.bold,
  color: colors.primary,
  textDecoration: "none",
  textTransform: "lowercase",
  letterSpacing: 0.18,
  pointerEvents: "none",

  span: {
    fontWeight: fontweights.regular,
  },

  "&:after": {
    content: "'_'",
    animation: blink + " 1s step-end infinite",
  },
})

const Header = () => {
  return (
    <header css={HeaderStyles}>
      <div css={LogoStyles}>
        Torkil <span>in Tech</span>
      </div>
    </header>
  )
}

export default Header
