import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"

import { mode } from "../styles/global"
import { grids, colors, mq } from "../styles/variables"
import Header from "../components/header"
import Footer from "../components/footer"

const LayoutStyles = mq({
  display: "grid",
  grid: grids.siteRows,
  minHeight: "100vh",
  backgroundColor: colors.primaryBg,
})

const Layout = ({ children }) => {
  return (
    <div css={LayoutStyles}>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css?family=Libre+Franklin:100,400,800");
        `}
      />
      <Global styles={mode} />
      <Header />

      {children}

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
