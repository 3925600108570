import React from "react"

import { grids, colors, fontsizes, mq } from "../styles/variables"
import Link from "./link"

const FooterStyles = mq({
  position: "relative",
  display: "grid",
  gridArea: "footer",
  grid: grids.siteColumns,
  gridColumnGap: grids.siteColumnGap,
  fontSize: fontsizes.tiny,
  letterSpacing: ".18em",
  textTransform: "uppercase",
  backgroundColor: colors.primaryBg,
  padding: "80px 0 40px 0",

  "&:before": {
    position: "absolute",
    content: "''",
    backgroundColor: colors.primary,
    height: 2,
    left: [20, 60],
    right: [20, 60],
    top: 0,
  },

  "& a": {
    color: colors.primary,
  },
  "& p": {
    fontSize: fontsizes.tiny,
    margin: "1em 0 0 0",
  },
  "& ul": {
    margin: "1em 0 0 0",
    padding: 0,
    listStyle: "none",
  },
  "& h5, h6": {
    fontSize: "inherit",
    margin: 0,
  },
  ".company": {
    gridColumn: ["2 / 4", "2 / 5", "2 / 5"],
    alignSelf: "start",
  },
  ".email": {
    gridColumn: ["4 / 6", "7 / 9", "8 / 11"],
    textAlign: "right",
    alignSelf: "start",
  },
  ".social-list": {
    gridColumn: ["4 / 6", "7 / 9", "8 / 11"],
    alignSelf: "end",
    textAlign: "right",
  },
  ".legal": {
    gridColumn: ["2 / 4", "2 / 5", "2 / 5"],
    alignSelf: "end",
    textAlign: "left",
    marginTop: 40,
  },
})

const Footer = () => {
  return (
    <footer css={FooterStyles}>
      <div className="company">
        <h5 className="bold">Torkil in Tech ApS</h5>
        <p>
          Kirsebærvangen 88,
          <br />
          DK-2765 Smørum,
          <br />
          Denmark
        </p>
        <p>CVR/VAT: 40343113</p>
      </div>
      <div className="email">
        <h6 className="bold">Write me</h6>
        <p>
          <Link href="mailto:hello@torkil.tech">hello@torkil.tech</Link>
        </p>
      </div>

      <div className="legal">
        <p>Copyright © {new Date().getFullYear()}</p>
      </div>

      <div className="social-list">
        <h6 className="bold">Connect on Social</h6>
        <ul>
          <li>
            <Link href="https://twitter.com/torkil_in_tech">Twitter</Link>
          </li>
          <li>
            <Link href="https://www.linkedin.com/in/torkil-in-tech/">
              LinkedIn
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
