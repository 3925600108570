import React from "react"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"

import { colors, fontsizes, fontweights } from "../styles/variables"

const LinkStyles = {
  position: "relative",
  color: colors.primary,
  zIndex: 0,

  div: {
    position: "absolute",
    backgroundColor: colors.hover,
    top: 0,
    bottom: 0,
    left: -2,
    right: -2,
    zIndex: -1,
    transformOrigin: "100% 100%",

    "&:nth-of-type(2)": {
      top: "unset",
      height: 3,
    },
  },
  "&:focus": {
    outline: "none",
  },
}

const ButtonStyles = {
  position: "relative",
  textDecoration: "none",
  zIndex: 1,
  backgroundColor: colors.primary,
  color: colors.primaryBg,
  border: "none",
  minWidth: 220,
  display: "inline-block",
  boxSizing: "border-box",
  fontSize: fontsizes.small,
  textTransform: "uppercase",
  padding: "7px 20px",
  fontWeight: fontweights.bold,
  letterSpacing: ".18em",
  borderRadius: 50,

  "&[disabled]": {
    boxShadow: "none !important",
    opacity: 0.3,
    cursor: "default !important",
    color: colors.primaryBg + " !important",
  },
  "&:hover": {
    cursor: "pointer",
    boxShadow: colors.shadow,
  },
  "&:focus": {
    outline: "none",
    boxShadow: colors.shadow,
  },
}

const ComponentLink = ({ children, rel, to, href, asButton }) => {
  if (to && !href) {
    return (
      <TransitionLink
        exit={{
          length: 1,
        }}
        entry={{
          delay: 0.6,
        }}
        to={to}
        css={asButton ? ButtonStyles : LinkStyles}
      >
        {children}
      </TransitionLink>
    )
  } else if (href && !to) {
    return (
      <a css={asButton ? ButtonStyles : LinkStyles} rel={rel} href={href}>
        {children}
      </a>
    )
  } else {
    throw new Error()
  }
}

ComponentLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  clickhandler: PropTypes.func,
}

export default ComponentLink
