import {
  colors,
  fontweights,
  fontsizes,
  mq,
  fontfamilies,
  lineheights,
} from "./variables"

export const mode = mq({
  body: {
    margin: 0,
    color: colors.primary,
    backgroundColor: colors.primaryBg,
    fontFamily: fontfamilies.sans,
    fontWeight: fontweights.regular,
    lineHeight: lineheights.normal,
    "& h1, h2, h3, h4, h5, h6": {
      fontWeight: fontweights.regular,
    },
    " & p": {
      fontSize: fontsizes.small,
      lineHeight: lineheights.normal,
    },
    "& .bold": {
      fontWeight: fontweights.bold,
    },
    strong: {
      fontWeight: fontweights.bold,
    },
    ".tl-edges": {
      gridArea: "main",
    },
  },
})
